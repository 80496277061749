import React from "react";
import "./App.css";

function App() {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "black",
    color: "#39ff14", // Neon green color
    fontFamily: "'Orbitron', sans-serif", // Futuristic font
    fontSize: "3rem",
    textShadow: "0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14",
  };

  return (
    <div style={containerStyle}>
      <h1>Coming Soon</h1>
    </div>
  );
}

export default App;